import React from "react";

const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
    /* #region Main App */

    const [getUserDataToken, setGetUserDataToken] = React.useState(null); // Handles the token to fetch user data
    const [refreshUserData, setRefreshUserData] = React.useState(false); // When true, it will refresh the user data

    const [showDialogBox, setShowDialogBox] = React.useState(false); // Handles the visibility of the dialog box
    const [dialogBoxLoading, setDialogBoxLoading] = React.useState(false); // Handles the loading state of the dialog box
    const [dialogBoxTitleHeading, setDialogBoxTitleHeading] = React.useState(""); // Handles the title heading to be displayed in the dialog box
    const [dialogBoxTitle, setDialogBoxTitle] = React.useState(""); // Handles the title to be displayed in the dialog box
    const [dialogBoxMessage, setDialogBoxMessage] = React.useState(""); // Handles the message to be displayed in the dialog box
    const [dialogBoxType, setDialogBoxType] = React.useState("save"); // Handles the type of dialog box
    const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false); // Handles the visibility of the unsaved changes modal
    /* #endregion */

    /* #region BOImages */
    const [sidePanelVisible, setSidePanelVisible] = React.useState(false); // Handles the visibility of the side panel
    const [sidePanelSection, setSidePanelSection] = React.useState("Products"); // Handles the section to be displayed in the side panel
    const [sidePanelLabelBrandID, setSidePanelLabelBrandID] = React.useState(null); // Handles the labelBrandID to fetch products for the side panel
    const [sidePanelProducts, setSidePanelProducts] = React.useState([]); // Handles the products to be displayed in the side panel
    const [sidePanelProductSearchName, setSidePanelProductSearchName] = React.useState(null); // Handles the search name to fetch products for the side panel
    const [sidePanelBOtagProductLink, setSidePanelBOtagProductLink] = React.useState(null); // Handles the product link to be linked to a BOtag
    const [BOTagsList, setBOTagsList] = React.useState([]); // Handles the list of BOtags
    const [sidePanelHasNewLinkedProducts, setSidePanelHasNewLinkedProducts] = React.useState(false); // Handles the visibility of the new linked products dot
    const [sidePanelSelectedProductID, setSidePanelSelectedProductID] = React.useState(undefined); // Handles the selected product ID
    const [sidePanelSelectedProductNumber, setSidePanelSelectedProductNumber] = React.useState(undefined); // Handles the selected product number
    const [brandNotSelectedShowError, setBrandNotSelectedShowError] = React.useState(false); // Handles the visibility of the brand not selected error
    /* #endregion */

    /* const resetBOImagePage = () => {
        setSidePanelVisible(false);
        setSidePanelSection("Products");
        setSidePanelLabelBrandID(null);
        setSidePanelProducts([]);
        setSidePanelProductSearchName(null);
        setSidePanelBOtagProductLink(null);
        setBOTagsList([]);
        setSidePanelHasNewLinkedProducts(false);
        setSidePanelSelectedProductID(undefined);
        setSidePanelSelectedProductNumber(undefined);
        setBrandNotSelectedShowError(false);
    }; */

    return (
        <AppContext.Provider
            value={{
                getUserDataToken,
                setGetUserDataToken,
                refreshUserData,
                setRefreshUserData,

                showDialogBox,
                setShowDialogBox,
                dialogBoxLoading,
                setDialogBoxLoading,
                dialogBoxTitleHeading,
                setDialogBoxTitleHeading,
                dialogBoxTitle,
                setDialogBoxTitle,
                dialogBoxMessage,
                setDialogBoxMessage,
                dialogBoxType,
                setDialogBoxType,
                hasUnsavedChanges,
                setHasUnsavedChanges,

                sidePanelVisible,
                setSidePanelVisible,
                sidePanelSection,
                setSidePanelSection,
                sidePanelLabelBrandID,
                setSidePanelLabelBrandID,
                sidePanelProducts,
                setSidePanelProducts,
                sidePanelProductSearchName,
                setSidePanelProductSearchName,
                sidePanelBOtagProductLink,
                setSidePanelBOtagProductLink,
                BOTagsList,
                setBOTagsList,
                sidePanelHasNewLinkedProducts,
                setSidePanelHasNewLinkedProducts,
                sidePanelSelectedProductID,
                setSidePanelSelectedProductID,
                sidePanelSelectedProductNumber,
                setSidePanelSelectedProductNumber,
                brandNotSelectedShowError,
                setBrandNotSelectedShowError
            }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;
