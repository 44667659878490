import React, { useEffect, useState } from "react";
import InfinityGridStoresCards from "../../common/cardContainers/InfinityGridStoresCards";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import * as Library from "../../../utils/Library";
import { AppQueryClient } from "../../../api/queryClient";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import { formatDefaultDateTime } from "../../../utils/Formatter";
import { useOutletContext } from "react-router";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
/* #endregion */

const infiniteGridQuerykey = "StoresGrid";
const listQueryKey = "StoresList";

export default function StoresPage() {
    document.title = `${APP_TITLE} - Stores`;

    /* #region STATES */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    const [isLoading, setIsLoading] = useState(false);

    const [removeID, setRemoveID] = useState(null);
    const [toogleSuspendData, setToogleSuspendData] = useState(null);

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props, onClickRefreshAction);
    }, [props?.layoutType]);
    /* #endregion */

    /* #region LIST COMPONENT PROPS */
    const listColumns = [
        {
            name: "ID",
            selector: row => row?.ourID ?? "--"
        },
        {
            name: "Store",
            selector: row => row?.name ?? "--"
        },
        {
            name: "Invited by",
            selector: row => row?.invitedBy ?? "--"
        },
        {
            name: "Invited on",
            selector: row => row?.invitedOn ?? "--"
        },
        {
            name: "Affiliated on",
            selector: row => formatDefaultDateTime(row?.affiliatedOn)
        },
        {
            name: "Contact person",
            selector: row => row?.contactPerson ?? "--"
        },
        {
            name: "Phone number",
            selector: row => row?.phoneNumber ?? "--"
        },
        {
            name: "Contact email",
            selector: row => row?.contactEmail ?? "--"
        },
        {
            name: "Country",
            selector: row => row?.country ?? "--"
        }
    ];
    /* #endregion */

    /* #region METHODS */
    const handleDelete = async itemData => {
        const itemID = itemData?.id;

        if (!itemID) {
            Library.showErrorMessage("Invalid item ID!");
            return;
        }

        setRemoveID(itemID);
    };

    const makeDeleteRequest = itemID => {
        setIsLoading(true);

        /*setTimeout(() => {
            setIsLoading(false);
            setRemoveID(null);
            Library.showSuccessMessage("Store deleted successfully!");
        }, 2000);*/

        Library.makePostRequest("deleteGroupStore", { storeID: itemID }, false, setIsLoading)
            .then(res => {
                if (res.data.status !== 200) return;
                setIsLoading(false);
                setRemoveID(null);
                Library.showSuccessMessage("Store deleted successfully!");
                props?.getUserDataFunction(localStorage.getItem("token"));
                onClickRefreshAction();
            })
            .catch(err => {
                setIsLoading(false);
                setRemoveID(null);
            });
    };

    const handleToogleSuspend = itemData => {
        const itemID = itemData?.id;

        if (!itemID) {
            Library.showErrorMessage("Invalid item ID!");
            return;
        }

        setToogleSuspendData(itemData);
    };

    const toogleSuspend = itemData => {
        setIsLoading(true);

        /*setTimeout(() => {
            setIsLoading(false);
            setToogleSuspendData(null);
            Library.showSuccessMessage("Store suspended successfully!");
        }, 2000);*/

        const groupCompanyID = localStorage.getItem("currentCompanyID");
        const itemID = itemData?.id;
        const status = itemData?.status === "Active" ? "Suspended" : "Active";

        Library.makePostRequest(
            "updateGroupStoreStatus",
            {
                groupCompanyID,
                storeID: itemID,
                status
            },
            false,
            setIsLoading
        )
            .then(res => {
                if (res.data.status !== 200) return;
                setIsLoading(false);
                setToogleSuspendData(null);
                Library.showSuccessMessage(
                    status === "Active" ? "Store activated successfully!" : "Store suspended successfully!"
                );
                const token = localStorage.getItem("token");
                props?.getUserDataFunction(token);
                onClickRefreshAction();
            })
            .catch(err => {
                setIsLoading(false);
                setToogleSuspendData(null);
            });
    };

    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };

    /* #endregion */

    return (
        <StyledPage>
            <FullScreenOverlay show={toogleSuspendData !== null}>
                <DialogBoxComponent
                    title={toogleSuspendData?.status === "Active" ? "Suspend Store" : "Activate Store"}
                    message={
                        toogleSuspendData?.status === "Active"
                            ? `Are you sure you want to suspend ${toogleSuspendData?.name} store?`
                            : `Are you sure you want to activate ${toogleSuspendData?.name} store?`
                    }
                    //message={`${toogleSuspendData?.storeName} status will become SUSPENDED until you reactivate the account. ${toogleSuspendData?.storeName} will be logged out of their account. This may take up to 30 minutes to take effect. No email will be sent.`}
                    onClickYes={() => {
                        toogleSuspend(toogleSuspendData);
                    }}
                    loading={isLoading}
                    loadingHeading={
                        toogleSuspendData?.status === "Active" ? "Suspending Store..." : "Activating Store..."
                    }
                    onClickNo={() => {
                        setToogleSuspendData(null);
                    }}
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={removeID !== null}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-red-500)"
                    title="Removing Store"
                    message="Are you sure you want to remove this store from your account?"
                    onClickYes={() => {
                        makeDeleteRequest(removeID);
                    }}
                    loading={isLoading}
                    loadingHeading="Removing Store..."
                    onClickNo={() => {
                        setRemoveID(null);
                    }}
                />
            </FullScreenOverlay>

            {viewLayoutStyle === "Grid" && (
                <InfinityGridStoresCards
                    minColumnWidth="370px"
                    limit="8"
                    //onDeleteAction={handleDelete}
                    //onSuspendAction={handleToogleSuspend}
                    queryKey={infiniteGridQuerykey}
                    statusFromPath="status"
                />
            )}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="Stores"
                    columns={listColumns}
                    api={{
                        endpoint: "getStoresData",
                        useCommonPostRequest: true,
                        commonContext: "Stores",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID")
                        }
                        //onSuccess: mapListData
                    }}
                    pageLimit={20}
                    currentPage={1}
                    queryKey={"StoresList"}
                    actions={{
                        includeActions: true,
                        extraActions: [
                            {
                                title: "Details"
                            },
                            {
                                title: "Add staff"
                            }
                        ],
                        editIsVisible: false
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: [
                            { colorClass: "text-green-500", forValues: ["Invited"] },
                            { colorClass: "text-blue-600", forValues: ["Active"] }
                        ]
                    }}
                />
            )}
        </StyledPage>
    );
}

function setupHeaders(props, onRefreshAction) {
    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Stores", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/stores", active: true, disabled: false, title: "Stores" },
        { to: "/stores/staff", active: false, disabled: true, title: "Staff" }
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: false, title: "View", icon: "eye" },
        { to: "#", active: false, disabled: true, title: "Add", icon: "plus" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        visible: true,
        gridEnabled: true,
        listEnabled: true,
        onRefreshAction: onRefreshAction
    });
}
