import React, { useRef, useState } from "react";
import styled from "styled-components";
import * as Library from "../../../utils/Library";
import ContainerLoader from "../loaders/ContainerLoader";

/**
 * Component for uploading images with drag and drop functionality.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onImageUpload - Callback function triggered when an image is uploaded. If the uploadToContainer prop is provided, the image data URL is passed to this function. Otherwise, the image Base64 data is passed.
 * @param {string} props.uploadToContainer - (Optional) The container to upload the image to.
 * @param {object} props.disabled - (Optional) An object containing the disabled state and message.
 * @param {boolean} props.disabled.isDisabled - (Optional) The disabled state.
 * @param {string} props.disabled.message - (Optional) The disabled message.
 * @param {function} props.disabled.onDisabledNotify - (Optional) Callback function triggered when the user tries to upload an image while the component is disabled.
 * @returns {JSX.Element} The rendered component.
 */
export default function ImageUploading(props) {
    const StyledPage = styled.div`
        &.Frame198 {
            width: 100%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 9px;
            display: inline-flex;
            cursor: pointer;
        }

        &.drag-over {
            border-radius: 8px;
            border: 2px dashed var(--text-icons-blue-500, #00aeef);
            background: rgba(0, 96, 204, 0.08);
        }

        &.drag-over.disabled {
            border: 2px dashed var(--text-icons-red-500);
            background: rgba(209, 209, 209, 0.08);
        }

        .Frame288 {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;
            display: flex;
        }

        .Upload01 {
            width: 24px;
            height: 24px;
            position: relative;
        }

        .Icon {
            width: 18px;
            height: 18px;
            left: 3px;
            top: 3px;
            position: absolute;
            border: 2px #d1d1d1 solid;
        }

        .Frame199 {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 4px;
            display: flex;
        }

        .ClickToUploadAnImage {
            text-align: center;
            color: #d1d1d1;
            font-size: 18px;
            font-family: Roboto;
            font-weight: 400;
            word-wrap: break-word;
        }

        .OrYouCanDragAndDropFilesHere {
            text-align: center;
            color: #7c868b;
            font-size: 14px;
            font-family: Roboto;
            font-weight: 400;
            letter-spacing: 0.04px;
            word-wrap: break-word;
        }
    `;

    /* #region USE STATES */
    const fileInput = useRef(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    /* #endregion */

    /* #region METHODS */
    const handleOnClick = () => {
        if (props?.disabled?.isDisabled) {
            Library.showErrorMessage(props.disabled.message);
            if (props?.disabled?.onDisabledNotify) props.disabled.onDisabledNotify();
            return;
        }
        document.getElementById("file").click();
    };

    const handleImageChange = e => {
        if (props?.uploadToContainer) {
            const file = e.target.files[0];
            FileUpload(file);
        } else {
            const reader = new FileReader();
            const file = e.target.files[0];

            reader.onloadend = () => {
                if (props?.onImageUpload) props.onImageUpload(reader.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleDrop = event => {
        event.preventDefault();
        setIsDragOver(false);

        if (props?.disabled?.isDisabled) {
            Library.showErrorMessage(props.disabled.message);
            if (props?.disabled?.onDisabledNotify) props.disabled.onDisabledNotify();
            return;
        }

        if (props?.uploadToContainer) {
            const file = event.dataTransfer.files[0];
            FileUpload(file);
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            if (props?.onImageUpload) props.onImageUpload(reader.result);
        };
        reader.readAsDataURL(event.dataTransfer.files[0]);
    };

    const handleDragOver = event => {
        event.preventDefault();
        setIsDragOver(true);
    };

    const FileUpload = async file => {
        Library.uploadBlobFile(file, props.uploadToContainer, setIsUploading)
            .then(res => {
                if (res.data.status === 200) {
                    const url = res.data.data.imageURL;
                    if (props?.onImageUpload) props.onImageUpload(url);
                }
            })
            .catch(error => {
                console.log(error);
                Library.showErrorMessage("Error uploading image: " + error.message);
            });
    };

    /* #endregion */

    return (
        <StyledPage
            className={`Frame198 ${isDragOver ? "drag-over" : ""} ${props?.disabled?.isDisabled ? "disabled" : ""}`}
            onDragOver={handleDragOver}
            onDragLeave={() => setIsDragOver(false)}
            onDrop={handleDrop}
            onClick={handleOnClick}>
            <ContainerLoader isLoading={isUploading} isLoadingOverlay={true}>
                <div className="Frame288">
                    <div className="Upload01">
                        <input
                            type="file"
                            id="file"
                            ref={fileInput}
                            style={{ display: "none" }}
                            onChange={handleImageChange}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path
                                d="M21.5 15V16.2C21.5 17.8802 21.5 18.7202 21.173 19.362C20.8854 19.9265 20.4265 20.3854 19.862 20.673C19.2202 21 18.3802 21 16.7 21H8.3C6.61984 21 5.77976 21 5.13803 20.673C4.57354 20.3854 4.1146 19.9265 3.82698 19.362C3.5 18.7202 3.5 17.8802 3.5 16.2V15M17.5 8L12.5 3M12.5 3L7.5 8M12.5 3V15"
                                stroke="#D1D1D1"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    <div className="Frame199">
                        <div className="ClickToUploadAnImage">Click to upload an image</div>
                        <div className="OrYouCanDragAndDropFilesHere">OR you can drag and drop files here</div>
                    </div>
                </div>
            </ContainerLoader>
        </StyledPage>
    );
}
