import React from "react";

/**
 * Renders a dialog box component.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.headingColor - The color of the heading.
 * @param {string} props.heading - The heading text.
 * @param {string} props.title - The title text.
 * @param {string} props.message - The message text.
 * @param {function} props.onClickYes - The function to be called when the "YES" button is clicked.
 * @param {function} props.onClickNo - The function to be called when the "NO" button is clicked.
 * @param {string} props.loading - Indicates whether the component is in a loading state.
 * @param {string} props.loadingHeading - The heading text to be displayed when the component is in a loading state.
 * @param {string} props.cancelText - The text to be displayed on the "NO" button.
 * @param {string} props.confirmText - The text to be displayed on the "YES" button.
 * @returns {JSX.Element} The rendered dialog box component.
 */
const DialogBoxComponent = ({
    headingColor = "var(--text-icons-blue-500)",
    heading = "JUST CHECKING...",
    title,
    message,
    onClickYes,
    onClickNo,
    loading,
    loadingHeading,
    cancelText = "NO",
    confirmText = "YES"
}) => {
    return (
        <>
            <div className="CardBox">
                <div className="CardBox-container">
                    <h4 className="CardBox-heading dbox" style={{ color: headingColor, textTransform: "uppercase" }}>
                        {loading && loadingHeading ? loadingHeading : heading}
                    </h4>
                    <div className="CardBox-container-hr2"></div>
                    {loading === "true" || loading === true ? (
                        <div
                            style={{
                                height: "130px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                            // className="CardBox-container-body"
                        >
                            <div className="loader" style={{ height: "100%" }}>
                                <div className="dot dot1"></div>
                                <div className="dot dot2"></div>
                                <div className="dot dot3"></div>
                                <div className="dot dot4"></div>
                            </div>
                        </div>
                    ) : (
                        <div className="CardBox-container-body">
                            <h3 className=" ready-delete" dangerouslySetInnerHTML={{ __html: title }}></h3>

                            <p className="Delete-CardBox-pregraf" dangerouslySetInnerHTML={{ __html: message }}></p>
                            <div className="CardBox-buttons button-marginb">
                                <button className="CardBox-button-a" onClick={onClickNo}>
                                    {cancelText}
                                </button>
                                <button className="CardBox-button-b" onClick={onClickYes}>
                                    {confirmText}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default DialogBoxComponent;
