import React from "react";
import styled from "styled-components";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        display: flex;
        width: 120px;
        height: 35px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;

        border-radius: 4px;
        background: var(--Buttons-Green-Outline);

        // Added Border to fix small issue
        //border: 1px solid var(--Buttons-All-Platforms-Gray-300, #1e2631);
    }

    .Button-Text-Small {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Button-Text-Small */
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.125px;
    }

    // HOVER CHANGES
    &.enabled:hover {
        background: var(--Buttons-Green-Hover) !important;
        cursor: pointer;
    }

    // ACTIVE CHANGES
    &.enabled.active {
        background: var(--Buttons-Green-Active);
    }

    // DISABLED CHANGES
    &.disabled {
        background: var(--Buttons-All-Platforms-Gray-300);
        cursor: not-allowed;
    }

    &.disabled .Button-Text-Small {
        color: var(--Buttons-All-Platforms-Gray-200, #4f5b6d);
        cursor: not-allowed;
    }

    /* TODO: DISABLED HOVER CSS NON EXISTENT
    &.disabled:hover {
         border: 1px solid var(--BOStageB-Green-Outline);
    } */

    // BO STAGE COLORS
    &.BOstageColors.enabled:hover {
        background: var(--Buttons-Green-Hover) !important;
    }

    &.BOstageColors.enabled.active {
        background: var(--Buttons-Green-Active) !important;
    }
`;
/* #endregion */

/**
 * Button large Solid component.
 *
 * @component
 * @example
 * // Usage:
 * <BtnLargeSolid text="Click me" disabled="false" />
 *
 * @param {Object} props - The component props.
 * @param {string} props.text - The text to display on the button.
 * @param {string} props.isActive - Whether the button is active or not.
 * @param {string} props.disabled - Whether the button is disabled or not.
 * @param {string} [props.className] - Additional CSS class name for the component.
 * @param {string} [props.style] - Additional CSS style for the component.
 * @param {string} [props.textStyle] - Additional CSS style for the component.
 * @param {function} [props.onClick] - The function to call when the button is clicked.
 * @param {string} props.useBOstageColors - Default: false. Whether buttons should use the BO stage colors.
 *
 * @returns {JSX.Element} The rendered BtnLargeSolid component.
 */
export default function BtnLargeSolid(props) {
    return (
        <StyledPage
            className={`btn-large ${props?.disabled === true ? "disabled" : "enabled"} ${props?.className ?? ""} ${
                props?.useBOstageColors ? "BOstageColors" : ""
            } ${props?.isActive ? "active" : ""}`}
            onClick={props?.disabled ? undefined : props?.onClick}
            style={props?.style}>
            <label className="Button-Text-Small text-one-line" style={props?.textStyle}>
                {props?.text || props?.children || "--"}
            </label>
        </StyledPage>
    );
}
