import StoresPage from "../components/pages/stores/StoresPage";

const routes = [
    {
        path: "/stores",
        element: <StoresPage />
    }
];

export default routes;
