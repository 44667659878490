import React, { useEffect, useState } from "react";
import InfinityGridBOImagesCards from "../../common/cardContainers/InfinityGridBOImagesCards";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import { formatDefaultDateTime, formatDefaultDate } from "../../../utils/Formatter";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import PublishPopUp from "../../common/PublishPopUp";
import * as Library from "../../../utils/Library";
import { AppQueryClient } from "../../../api/queryClient";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import { useNavigate, useOutletContext } from "react-router";
import AppContext from "../../../AppContext";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
/* #endregion */

export default function BOImagesPage() {
    const infiniteGridQuerykey = "BOImagesGrid";
    const listQueryKey = "BOImagesList";

    document.title = `${APP_TITLE} - BOImages`;

    const { MainLayoutProps, layoutType } = useOutletContext();
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    /* #region STATES */
    const {
        setSidePanelBOtagProductLink,
        //setSelectedBOtag,
        setBOTagsList,
        setSidePanelVisible,
        setSidePanelSection,
        setSidePanelProducts,
        setSidePanelHasNewLinkedProducts,
        setSidePanelLabelBrandID,
        setSidePanelProductSearchName,
        setSidePanelSelectedProductID,
        setSidePanelSelectedProductNumber,
        setBrandNotSelectedShowError
    } = React.useContext(AppContext);

    const navigate = useNavigate();
    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    const [isLoading, setIsLoading] = useState(false);
    const [publishPopUp, setPublishPopUp] = useState(false);
    const [publishBOimageID, setPublishBOimageID] = useState(null);

    const [removeID, setRemoveID] = useState(null);
    const [toogleSuspendData, setToogleSuspendData] = useState(null);

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props);
    }, [props?.layoutType]);

    useEffect(() => {
        setSidePanelProducts([]);
        setBOTagsList([]);
        setSidePanelVisible(false);
        setSidePanelSection("Products");
        setSidePanelBOtagProductLink(null);
        setSidePanelHasNewLinkedProducts(false);
        setSidePanelProductSearchName(null);
        setSidePanelLabelBrandID(null);
        setSidePanelSelectedProductID(undefined);
        setSidePanelSelectedProductNumber(undefined);
        setBrandNotSelectedShowError(false);
    }, []);
    /* #endregion */

    /* #region METHODS */
    const handleEdit = itemData => {
        const itemID = itemData?._id;

        if (!itemID) {
            Library.showErrorMessage("Invalid item ID!");
            return;
        }

        navigate(`/boimages/edit/${itemID}`);
    };

    const handleDelete = async itemData => {
        const itemID = itemData?._id;

        if (!itemID) {
            Library.showErrorMessage("Invalid item ID!");
            return;
        }

        setRemoveID(itemID);
    };

    const makeDeleteRequest = itemID => {
        setIsLoading(true);

        /*setTimeout(() => {
        setIsLoading(false);
        setRemoveID(null);
        Library.showSuccessMessage("BOImage deleted successfully!");
    }, 2000);*/

        Library.makePostRequest("deleteBOimage", { BOimageID: itemID }, false, setIsLoading)
            .then(res => {
                if (res.data.status !== 200) return;
                setIsLoading(false);
                setRemoveID(null);
                Library.showSuccessMessage("BOImage deleted successfully!");
                props?.getUserDataFunction(localStorage.getItem("token"));
                onClickRefreshAction();
            })
            .catch(err => {
                setIsLoading(false);
                setRemoveID(null);
            });
    };

    const handleToogleSuspend = itemData => {
        const itemID = itemData?._id;

        if (!itemID) {
            Library.showErrorMessage("Invalid item ID!");
            return;
        }

        setToogleSuspendData(itemData);
    };

    const toogleSuspend = itemData => {
        setIsLoading(true);

        /*setTimeout(() => {
        setIsLoading(false);
        setToogleSuspendData(null);
        Library.showSuccessMessage("BOImage suspended successfully!");
    }, 2000);*/

        //const groupCompanyID = localStorage.getItem("currentCompanyID");
        const itemID = itemData?._id;
        const status = itemData?.status === "Active" ? "Suspended" : "Active";

        Library.makePostRequest(
            "updateBOimageStatus",
            {
                //groupCompanyID,
                BOimageID: itemID,
                status
            },
            false,
            setIsLoading
        )
            .then(res => {
                if (res.data.status !== 200) return;
                setIsLoading(false);
                setToogleSuspendData(null);
                Library.showSuccessMessage(
                    status === "Active" ? "BOImage activated successfully!" : "BOImage suspended successfully!"
                );

                props?.getUserDataFunction(localStorage.getItem("token"));
                onClickRefreshAction();
            })
            .catch(err => {
                setIsLoading(false);
                setToogleSuspendData(null);
            });
    };

    const onClickRefreshAction = () => {
        if (props?.layoutType === "List") AppQueryClient.invalidateQueries(listQueryKey);
        else AppQueryClient.invalidateQueries(infiniteGridQuerykey);
    };

    const onPublishClick = BOimage => {
        setPublishBOimageID(BOimage?.botagID);
        setPublishPopUp(true);
    };

    const onDetailsClick = BOimage => {
        navigate(`/boimages/${BOimage?._id}`);
    };
    /* #endregion */

    /* #region LIST COMPONENT PROPS */
    const listColumns = [
        {
            name: "ID",
            selector: row => row?.botagID ?? "--"
        },
        {
            name: "Active Period",
            selector: row =>
                row?.activePeriodStart && row?.activePeriodEnd
                    ? `${formatDefaultDate(row?.activePeriodStart)} to ${formatDefaultDate(row?.activePeriodEnd)}`
                    : "--"
        },
        {
            name: "Created by",
            selector: row => row?.createdBy ?? "--"
        },
        {
            name: "Created On",
            selector: row => formatDefaultDateTime(row?.createdOn)
        }
    ];
    /* #endregion */

    return (
        <StyledPage>
            <FullScreenOverlay show={toogleSuspendData !== null}>
                <DialogBoxComponent
                    title={toogleSuspendData?.status === "Active" ? "Suspend BOImage" : "Activate BOImage"}
                    message={
                        toogleSuspendData?.status === "Active"
                            ? `Are you sure you want to suspend ${toogleSuspendData?.name} BOImage?`
                            : `Are you sure you want to activate ${toogleSuspendData?.name} BOImage?`
                    }
                    onClickYes={() => {
                        toogleSuspend(toogleSuspendData);
                    }}
                    loading={isLoading}
                    loadingHeading={
                        toogleSuspendData?.status === "Active" ? "Suspending BOImage..." : "Activating BOImage..."
                    }
                    onClickNo={() => {
                        setToogleSuspendData(null);
                    }}
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={removeID !== null}>
                <DialogBoxComponent
                    headingColor="var(--text-icons-red-500)"
                    title="Removing BOImage"
                    message="Are you sure you want to remove this BOImage?"
                    onClickYes={() => {
                        makeDeleteRequest(removeID);
                    }}
                    loading={isLoading}
                    loadingHeading="Removing BOImage..."
                    onClickNo={() => {
                        setRemoveID(null);
                    }}
                />
            </FullScreenOverlay>

            {publishPopUp && (
                <FullScreenOverlay
                    children={<PublishPopUp setPublishPopUp={setPublishPopUp} BOimageID={publishBOimageID} />}
                />
            )}
            {viewLayoutStyle === "Grid" && (
                <InfinityGridBOImagesCards
                    minColumnWidth="238px"
                    limit={12}
                    primaryButton={{ onClick: item => onDetailsClick(item) }}
                    secondaryButton={{ onClick: item => onPublishClick(item) }}
                    onEditAction={handleEdit}
                    onDeleteAction={handleDelete}
                    onSuspendAction={handleToogleSuspend}
                    queryKey={infiniteGridQuerykey}
                />
            )}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="BOImages"
                    columns={listColumns}
                    api={{
                        endpoint: "getBOimagesData",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID")
                        }
                        //onSuccess: mapListData
                    }}
                    pageLimit={20}
                    currentPage={1}
                    queryKey={listQueryKey}
                    actions={{
                        includeActions: true,
                        onClickEdit: handleEdit,
                        onClickRemove: handleDelete,
                        onClickSuspend: handleToogleSuspend,
                        //editIsVisible: false,
                        extraActions: [
                            {
                                title: "Details",
                                onClick: item => onDetailsClick(item)
                            },
                            {
                                title: "Publish",
                                onClick: item => onPublishClick(item)
                            }
                        ]
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: [
                            { colorClass: "text-green-500", forValues: ["Invited"] },
                            { colorClass: "text-blue-600", forValues: ["Active"] },
                            { colorClass: "text-orange-500", forValues: ["Suspended"] },
                            { colorClass: "text-red-500", forValues: ["Inactive", "Not connected"] }
                        ]
                    }}
                />
            )}
        </StyledPage>
    );
}

function setupHeaders(props) {
    props.setControlBarLeftBtns([
        { to: "#", disabled: false, title: "BOImages", icon: "fa-magnifying-glass", isSearchToogle: true }
    ]);
    props.setControlBarCenterBtns([{ to: "/boimages", active: true, disabled: false, title: "BOIMAGES" }]);
    props.setControlBarRightBtns([
        { to: "/boimages", active: true, disabled: false, title: "View", icon: "eye" },
        { to: "/boimages/create", active: false, disabled: false, title: "Add", icon: "plus" },
        { to: "/boimages/empty", active: false, disabled: false, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        visible: true,
        gridEnabled: true,
        listEnabled: true,
        onRefreshAction: undefined
    });
}
